html, body {
  width: 100%;
  height: 100%;
}

body {
  background-image: url("piney-lake.da7d9f2b.jpg");
  background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  overflow: hidden;
}

h1 {
  font-size: 65px;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #0079bf;
}

.flex-centered {
  justify-content: center;
  display: flex;
}

.card {
  background: #0009;
  border-radius: 6px;
  padding: 36px;
}

.header {
  color: #fff;
  text-align: center;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.service-block {
  align-items: center;
  margin-right: 18px;
  display: flex;
}

.service-logo {
  padding-right: 6px;
}

.fa {
  margin: 0 4px;
}

@media only screen and (width <= 440px), (height <= 400px) {
  h1 {
    font-size: 32px;
  }

  h3 {
    font-size: 18px;
  }

  .card {
    padding: 24px 12px;
  }
}

@media only screen and (height <= 400px) {
  .profile-img {
    width: 128px;
    height: 128px;
  }
}

/*# sourceMappingURL=index.6332f470.css.map */
