html, body {
  height: 100%;
  width: 100%;
}

body {
  background-image: url("../images/piney-lake.jpg");
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0;
  overflow: hidden;
}

h1 {
  font-size: 65px;
}

a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: #0079BF;
}

.flex-centered {
  display:flex;
  justify-content:center;
}

.card {
  background: rgba(0,0,0,0.6);
  padding: 36px;
  border-radius: 6px;
}

.header {
  align-items: center;
  color: white;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.service-block {
  display: flex;
  align-items: center;
  margin-right: 18px;
}

.service-logo {
  padding-right: 6px;
}

.fa {
  margin: 0 4px;
}

@media only screen and (max-width: 440px), (max-height: 400px) {
  h1 {
    font-size: 32px;
  }
  h3 {
    font-size: 18px;
  }
  .card {
    padding: 24px 12px;
  }
}

@media only screen and (max-height: 400px) {
  .profile-img {
    height: 128px;
    width: 128px;
  }
}
